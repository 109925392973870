/* eslint-disable prefer-destructuring */
import { isInternalLink, NotificationBarProps } from '@pelando/components';
import { OfferStatus } from '../../../domain/entities/Offer';

import {
  GetNotificationsCache,
  GetNotificationsVariables,
} from '../../../infra/api/notifications/types/GetNotifications';
import { ReadNotificationVariables } from '../../../infra/api/notifications/types/ReadNotification';
import { getDealUrl } from '../urls';

export const DEFAULT_NOTIFICATION_IMAGE = '/assets/foguinho.png';

export type MapNotificationBarPropsToHeaderParams = {
  notifications?: GetNotificationsCache & {
    count?: number;
  };
  fetchNotifications: (variables?: GetNotificationsVariables) => void;
  readNotification: (variables: ReadNotificationVariables) => void;
  readAllNotifications: () => void;
  clearNotifications: () => void;
  clearNotificationsCount: () => void;
  isLogged?: boolean;
  authModal: () => void;
};

// TODO: [sc-13494] Refatorar a forma como lidamos com os links e ações das notificações
const mapNotificationsEdges = (notifications?: GetNotificationsCache) =>
  notifications?.edges?.map((notification) => ({
      ...notification,
      dealImage: notification?.deal?.image?.url || DEFAULT_NOTIFICATION_IMAGE,
      deal: notification.deal && {
        ...notification?.deal,
        id: notification?.deal?.id || '',
        status: OfferStatus.ACTIVE,
        price: notification?.deal?.price,
        store: notification?.deal?.store,
      },
    })) || [];

const getCommentHash = (link?: string) => {
  if (!link || !isInternalLink(link)) {
    return '';
  }

  const url = new URL(link);

  let commentId: string;

  if (url.hash.includes('/')) {
    commentId = url.hash.split('/')[1];
  } else {
    commentId = url.hash.split('=')[1];
  }

  const commentQueryParam = `?comment=${commentId}`;

  return commentQueryParam;
};

const handleGetNotificationUrl =
  (notifications?: GetNotificationsCache) => (id: string, link?: string) => {
    const notification = notifications?.edges?.find(
      ({ deal }) => deal?.id === id
    );
    let url = link;
    const target = !isInternalLink(link) ? '_blank' : undefined;

    if (notification) {
      if (isInternalLink(link) && id) {
        const dealUrl = getDealUrl({
          id: notification?.deal?.id || '',
          title: notification?.deal?.title || '',
        });
        const commentHash = getCommentHash(link);
        url = `${dealUrl}${commentHash}`;
      }
    }

    return { url, target };
  };

export const mapNotificationBarPropsToHeader = ({
  notifications,
  fetchNotifications,
  readAllNotifications,
  readNotification,
  clearNotifications,
  clearNotificationsCount,
  isLogged,
  authModal,
}: MapNotificationBarPropsToHeaderParams): NotificationBarProps => ({
  notifications: mapNotificationsEdges(notifications),
  loading: notifications?.loading,
  notificationCount: isLogged ? notifications?.count || 0 : 0,
  hasNextPage: notifications?.pageInfo?.hasNextPage,
  onClickNotificationIcon: () =>
    isLogged ? fetchNotifications() : authModal(),
  onLoadMore: () =>
    fetchNotifications({
      after: notifications?.pageInfo?.endCursor,
    }),
  onReadAllClick: () => readAllNotifications(),
  onNotificationClick: (notificationId: string) =>
    readNotification({
      id: notificationId,
    }),
  onClose: () => clearNotifications(),
  onOpen: () => clearNotificationsCount(),
  getNotificationUrl: handleGetNotificationUrl(notifications),
});

import { SearchNavigationEvent, DarkThemeWrapper } from '@pelando/components';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { getTooltipOnboardingAlertHomeLocalStorage } from '@/infra/storage/onboardingManagement';
import { clearSearchTermFromRouteParam } from '@/presentation/pages/public/Search/helpers';
import dynamic from 'next/dynamic';
import { AuthKind, LoginComponentSourceName } from '../Authentication/types';
import { Header as HeaderContent } from './HeaderContent';
import { mapNotificationBarPropsToHeader } from '../../services/notifications/mapNotificationBarPropsToHeader';
import useSearchHandler from './hooks/useSearchHandler';
import { useAuthModal } from '../Authentication/hooks/useAuthModal';
import { LayoutContextCommands, useLayoutContext } from '../../Layout/context';
import { useAutocompleteSuggestion } from './hooks/useAutocompleteSuggestions';
import { useNotifications } from './hooks/useNotifications';
import { logoutHelper } from '../../../infra/api/auth/helpers/postLogout';
import { useAlertsCount } from '../../hooks/useAlertsCount';
import { useCurrentUserCache } from '../../hooks/useCurrentUserCache';
import { isBlackFridayHeaderEnabled } from '../../services/featureFlags';
import { useFrequentSearches } from './hooks/Search/useFrequentSearches';

const AuthModal = dynamic(
  () =>
    import('@/presentation/components/Authentication').then(
      (mod) => mod.default
    ),
  { ssr: false }
);

export enum SignupComponentSource {
  UnloggedDealSubmission = 1,
  SignupButtonOnHeader,
  UnloggedVote,
  SignupButtonOnTopOfMenu,
  SignupButtonOnBottomOfMenu,
  UnloggedCreateAlert,
  UnloggedNewComment,
  UnloggedNewCommentReply,
  UnloggedLikeComment,
  UnloggedRateStore,
  UnloggedDefault,
}

type HeaderProps = {
  hasBanner: boolean;
  googleClientId?: string;
};

export function Header({ hasBanner, googleClientId }: HeaderProps) {
  const router = useRouter();
  const [searchedValue, setSearchValue] = useState(
    clearSearchTermFromRouteParam((router.query.term as string) || '')
  );

  const {
    getSuggestions,
    suggestions,
    products,
    loading: suggestionsLoading,
  } = useAutocompleteSuggestion();
  const { count: alertsCount } = useAlertsCount();
  const { frequentSearches } = useFrequentSearches();

  const hasContextAlertRegister = getTooltipOnboardingAlertHomeLocalStorage();

  const {
    loading: notificationsLoading,
    edges,
    pageInfo,
    count,
    getNotifications,
    readAllNotifications,
    readNotification,
    clearNotifications,
    clearNotificationsCount,
  } = useNotifications();

  const { showModal, closeModal } = useAuthModal();
  const { contextCommands } = useLayoutContext();

  const {
    loggedUser: currentUser,
    status: authenticationStatus,
    isLogged,
  } = useCurrentUserCache();

  const handleLogout = async () => {
    await logoutHelper();
    router.push('/');
  };

  const showSignUpModal = useCallback(() => {
    showModal(
      <AuthModal
        componentSourceName={LoginComponentSourceName.headerMenuItem}
        kind={AuthKind.LOGIN}
        onCloseModal={closeModal}
        googleClientId={googleClientId}
      />
    );
  }, [showModal, closeModal, googleClientId]);

  const searchHandler = useSearchHandler({
    router,
    frequentSearches,
    searchedValue,
    setDefaultValue: setSearchValue,
    products,
    suggestions,
    suggestionsLoading,
    fetchSuggestions: getSuggestions,
  });

  const logoUrl = '/';

  // Handle the open searchbar command
  useEffect(() => {
    const subscription = contextCommands.subscribe((command) => {
      if (command !== LayoutContextCommands.OpenSearchbar) return;
      searchHandler.commands({
        type: SearchNavigationEvent.Focus,
        payload: undefined,
      });
    });

    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notificationBarProps = mapNotificationBarPropsToHeader({
    notifications: {
      edges,
      loading: notificationsLoading,
      pageInfo,
      count,
    },
    clearNotifications,
    clearNotificationsCount,
    fetchNotifications: getNotifications,
    readAllNotifications,
    readNotification,
    isLogged,
    authModal: showSignUpModal,
  });

  const isSearchPage = router.pathname.includes('/busca');

  useEffect(() => {
    const term = (router.query.term || '') as string;
    setSearchValue(clearSearchTermFromRouteParam(term));
  }, [router.query.term, setSearchValue]);

  return (
    <DarkThemeWrapper isActive={isBlackFridayHeaderEnabled()}>
      <HeaderContent
        hasBanner={hasBanner}
        logoUrl={logoUrl}
        searchHandler={searchHandler}
        currentUser={currentUser}
        notificationBarProps={notificationBarProps}
        authModal={showSignUpModal}
        authenticationStatus={authenticationStatus}
        logout={handleLogout}
        alertsCount={isLogged ? alertsCount : 0}
        isSearchPage={isSearchPage}
        hasContextAlertRegister={hasContextAlertRegister as unknown as boolean}
      />
    </DarkThemeWrapper>
  );
}
